<template>
<div class="cell" :style="style">
    <div class="image-container" @click="showImage">
        <div class="image" :style="`background-image:url(${props.imageSrc})`"></div>
    </div>
    <div v-show="focusOnImage" class="floating" @click="hideImage">
        <div class="image" :style="`background-image:url(${props.imageSrc})`" />
    </div>
</div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    imageSrc: String,
    style: Object
});

const focusOnImage = ref(false);

function showImage(){
    focusOnImage.value = true;
}
function hideImage(){
    focusOnImage.value = false;
}


</script>

<style scoped>
.cell{
    border-radius: 5px;
    height:100%;
    box-shadow: 0 0 0.5em 0.2em rgba(0,0,0,0.5);
}
.floating{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fade 0.2s linear;
    cursor: zoom-out;
}
.floating>div{
    width:90%;
    height: 90%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.image-container{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: clip;
}
.image{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.image-container:hover{
    transform: scale(1.01);
    transition: transform 0.5s ease;
}

.image-container:hover::after{
    content:"";
    position: absolute;
    width: 100%;
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    animation: fade 0.2s linear;
    z-index: 2; 
    cursor:pointer;
}

@keyframes fade{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
</style>