export default {
    navbar:{
        items:[
            "Inspirációk",
            "3D látványtervezés",
            "Lakberendezés",
            "Home staging",
            "Ingatlanközvetítés",
            "Ajánlatkérés"
        ]
    },
    cover:{
        title:"Szióka!",
    },
    inspirations:{
        title:"INSPIRÁCIÓK",
        boxLeft:[
            "Új otthonba költözik, mely teljes berendezésre vár?",
            "Felújítaná, átalakítaná lakását?",
            "Eladás előtt felfrissítené ingatlanát?",
            "Nincs ötlete? Netán túl sok ötlete van és nehéz közülük választani?",
        ],
        boxRight:[
            "Szín - és stílus tanácsadás",
            "Térszervezési ötletek",
            "Műszaki szaktanácsadás",
            "Átalakítási ötletek",
            "Berendezési ötletek"
        ],
        about: "Megmutatjuk a lehetőségeket, hogy megvalósíthassa álmai otthonát.",
        centered:"Személyre szabott lakberendezési tippek és tanácsok, változatos inspirációk a fillérestől az exkluzív megoldásokig."
    },
    visualDesign:{
        title:"3D LÁTVÁNYTERVEZÉS",
        right:"3D látványtervezés - Konzultáció személyesen - telefonon - online",
        left:[
            "Szeretné előre látni az elképzelt belső tereket?",
            "Elkerülné a felújításban rejlő csapdákat?",
            "Nem biztos benne melyik stílus, melyik színvilág lenne a legmegfelelőbb?",
            "Tippekre van szüksége a bútorozáshoz?",
        ],
        answers:[
            "Ha elkerülné a felesleges kiadásokat készíttessen 3D látványterveket!",
            "Fotórealisztikus képeken mutatjuk meg hogyan fog kinézni álomotthona.",
            "Ha már minden apró részlet a helyére került és tökéletesen elégedett a végeredménnyel akkor kezdődhetnek a kivitelezési munkálatok!",
            "3D látványtervezés szolgáltatásunk igényelhető személyes, de akár online konzultáció alapján is!",
            "Bárhol található az ingatlana mi megtervezzük és megmutatjuk a benne rejlő lehetőségeket, hogy Ön valóra válthassa álmait!",
        ]
    },
    interiorDesign:{
        title:"LAKBERENDEZÉS",
        left:"Komplett szolgáltatás tervezéstől kivitelezésig",
        right:"Kérje személyre szabott ajánlatunkat!",
        content:[
            "Ha nem csupán ötletekre, inspirációra vágyik, de szeretné, hogy a felújítás, a teljes kivitelezés gondját is levegyük a válláról válassza komplett lakberendezési szolgáltatásunkat.",
            "Az ötletektől a megvalósításig, megrendelőink igénye és elképzelései szerint végigvisszük a teljes felújítási folyamatot, minden részletre kiterjedően.",
            "Megtervezzük, látványterveken megmutatjuk és meg is valósítjuk Önnek álmai otthonát!",
        ]
    },
    homeStaging:{
        title:"HOME STAGING",
        left:"Ingatlan felkészítés eladás előtt",
        right:"Akár 10-15%-kal magasabb értékesítési ár!",
        content:[
            "Eladná vagy kiadná ingatlanát?",
            "Szeretné ezt a piaci ár felett és rövid határidőn belül lebonyolítani?",
            "Válassza a Home Staging szolgáltatásunkat!",
            "A Home staging lényege, hogy az ingatlant úgy alakítjuk át, hogy az legjobb arcát mutassa, kitűnjön a kínálatból.",
            "Szolgáltatás az Ön igényei szerint a home staging tanácsadástól az ingatlan frissítésen keresztül a komplex tervezési és kivitelezési feladatokig.",
            "Profi fotózással, ingatlan értékesítési tanácsadással, piaci elemzéssel.",
            "Átgondolt tervezéssel és némi ráfordítással akár milliókat is nyerhet az értékesítés során",
        ]
    },
    realEstate:{
        title:"INGATLANKÖZVETÍTÉS",
        right:[
            "Teljeskörű ingatlan értékesítési szolgáltatás",
            "Közel 10 éves ingatlanpiaci tapasztalat",
        ],
        content:[
            "Nem csupán megszépítjük eladó ingatlanát, az értékesítésben is segítünk!",
            "Nagy szakmai tapasztalattal, országos lefedettségű hálózaton keresztül működünk közre az eladásban!",
        ]
    },
    references:{
        title:"REFERENCIÁK",
    },
    offer:{
        title:"AJÁNLATKÉRÉS",
        email:"info@hexahome.hu",
        phones:[
            "+36 70 454 2374",
            "+36 30 593 7345"
        ],
        button:"Küldés",
        nameLabel:"Név",
        messageLabel:"Üzenet",
        noNameAlert:"Kérjük adja meg a nevét!",
        noMessageAlert:"Kérjük adja meg az üzenetet!",
        copyTooltip:"Másolás",
    }
}