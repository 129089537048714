<template>
    <div id="full-cover-background">
        <div id="animated-logo">
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue';


onMounted(()=>{
    removeCover();
});

function removeCover(){
    const cover = document.getElementById('full-cover-background');
    setTimeout(()=>{
        cover.remove();
    }, 2500);
}
</script>

<style scoped>
#full-cover-background{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: var(--document-background);
    z-index:100;
    animation: intro 2.5s ease-in-out forwards;
}
#animated-logo{
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 30vh;
    height: 30vh;
    background-image: url('~@/assets/large-banner.png');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 100;
    animation: logo 2.5s ease-in-out forwards;
}
@keyframes logo{
    0%{
        transform: translate(-50%, -50%) scale(0);
    }
    33%{
        transform: translate(-50%, -50%) scale(1);
    }
    66%{
        transform: translate(-50%, -50%) scale(1);
    }
    100%{
        transform: translate(-50%, -150%) scale(1);
    }
}
@keyframes intro{
    66%{
        opacity:1;
    }
    100%{
        opacity:0;
    }
}

</style>