<template>
    <div class="container">
        <div class="btn" :class="[isActive? 'active' : 'not-active']" @click="isActive = !isActive">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script setup>
import {computed,ref,watch} from "vue";

const emit = defineEmits(['toggled']);

const isActive = ref(false);

watch(isActive, (value) => {
    emit('toggled', value);
});
</script>

<style scoped>
.btn{
    width:80px;
    cursor:pointer;
}
span{
    display:block;
    width:100%;
    border-radius:3px;
    height:8px;
    transition: all 0.3s;
    position: relative;
    background-color:var(--navbar-foreground-highlighted);
}
span + span {
    margin-top: 14px;
}

.active span:nth-child(1){
    animation: ease 0.7s top-f forwards;
}
.not-active span:nth-child(1){
    animation: ease 0.7s top-b forwards;
}
.active span:nth-child(2){
    animation: ease 0.7s scaled-f forwards;
}
.not-active span:nth-child(2){
    animation: ease 0.7s scaled-b forwards;
}
.active span:nth-child(3){
    animation: ease 0.7s bottom-f forwards;
}
.not-active span:nth-child(3){
    animation: ease 0.7s bottom-b forwards;
}


@keyframes top-f{
    0%{
        top:0;
        transform: rotate(0);
    }
    50%{
        top:22px;
        transform: rotate(0);
    }
    100%{
        top:22px;
        transform: rotate(45deg);
    }
}
@keyframes top-b{
    0%{
        top:22px;
        transform: rotate(45deg);
    }
    50%{
        top:22px;
        transform: rotate(0);
    }
    100%{
        top:0;
        transform: rotate(0);
    }
}

@keyframes bottom-f{
    0%{
        bottom:0;
        transform: rotate(0);
    }
    50%{
        bottom:22px;
        transform: rotate(0);
    }
    100%{
        bottom:22px;
        transform: rotate(135deg);
    }
}
@keyframes bottom-b{
    0%{
        bottom:22px;
        transform: rotate(135deg);
    }
    50%{
        bottom:22px;
        transform: rotate(0);
    }
    100%{
        bottom:0;
        transform: rotate(0);
    }
}
@keyframes scaled-f{
    50%{
        transform: scale(0);
    }
    100%{
        transform: scale(0);
    }
}
@keyframes scaled-b{
    0%{
        transform: scale(0);
    }
    50%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}
</style>

