<template>
    <div class="collage-container" :style="`grid-template-columns:repeat(${props.columns},1fr);height:${props.columns*30}vh`">
        <Image class="hidden bordered" :id="imageGroup+index" :style="placement(image,index)" v-for="(image,index) in images" :key="index" :imageSrc="image" />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Image from "@/components/Image.vue";
import { triggerOnScroll,elementBottom,elementHeight } from 'basic-scroll-animations';
import { useSectionStore } from '@/store/sectionStore';

const store = useSectionStore();

const props = defineProps({
    imageGroup:String,
    columns:Number
});
const illustrations = require.context(
    "@/../public/collage",
    true,
    /.+\.((webp)|(png)|(jpg))$/
);
const images = ref(illustrations.keys().filter(s=>s.includes(props.imageGroup)).map(s=>`collage/${s.substr(2)}`));

function placement(name,index){
    return {
        'grid-column': `span ${name.split('.')[0].split('-')[1]}`,
        'grid-row': `span ${name.split('.')[0].split('-')[2]}`,
    }
}

onMounted(()=>{
    if(!store.isSafari){
        for (let index = 0; index < images.value.length; index++) {
            const e = `#${props.imageGroup}${index}`;
            triggerOnScroll(e)
                .after(elementBottom(e))
                .applyClass("slide-up")
                .removeClass("hidden");
        }
    }
    else{
        for(let index = 0; index < images.value.length; index++){
            const e = `#${props.imageGroup}${index}`;
            document.querySelector(e).classList.remove("hidden");
        }
    }
});
</script>

<style scoped>
.bordered{
    border: 2px solid var(--box-background);
}
.collage-container{
    margin:2rem 10%;
    display:grid;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
}
@media only screen and (max-width: 1279px) {
    .collage-container{
        margin:2rem 5%;
    }
}
</style>
<style>
.slide-up{
    animation: slide-up 0.5s ease-in-out;
}
.hidden{
    opacity: 0;
}
@keyframes slide-up{
    0%{
        opacity: 0;
        transform: translateY(100px);
    }
    100%{
        opacity: 1;
        transform: translateY(0px);
    }
}
</style>