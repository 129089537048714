<template>
    <Navbar @pressed="navigateTo" />
    <div id="main-anchor" :ref="(e)=>addSection(e)" />
    <Cover />
    <div class="content">
        <div id="inspiration-anchor" :ref="(e)=>addSection(e)" />
        <Inspirations />
        <div id="visualdesign-anchor" :ref="(e)=>addSection(e)" />
        <VisualDesign />
        <div id="interiordesign-anchor" :ref="(e)=>addSection(e)" />
        <InteriorDesign />
        <div id="homestaging-anchor" :ref="(e)=>addSection(e)" />
        <HomeStaging />
        <div id="realestate-anchor" :ref="(e)=>addSection(e)" />
        <RealEstate />
        <div id="references-anchor" :ref="(e)=>addSection(e)" />
        <Offer />
        <References />
        <!--div id="offer-anchor" :ref="(e)=>addSection(e)" /-->
        <Footer />
    </div>
</template>

<script setup>
import textMap from "@/scripts/locale.js";
import { onMounted,ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Cover from "@/components/Cover.vue";
import Inspirations from "@/contents/Inspirations.vue";
import VisualDesign from "@/contents/VisualDesign.vue";
import InteriorDesign from "@/contents/InteriorDesign.vue";
import HomeStaging from "@/contents/HomeStaging.vue";
import RealEstate from "@/contents/RealEstate.vue";
import References from "@/contents/References.vue";
import Offer from "@/contents/Offer.vue";
import { useSectionStore } from "@/store/sectionStore";

const store = useSectionStore();

const content = ref(textMap);
const sections = ref([]);

function navigateTo(index){
    store.openSection(index);
    store.scrollTo(index);
}

function addSection(e){
    sections.value.push(e);
}

onMounted(()=>{
    for(const section of sections.value){
        store.addSection(section);
    }
    store.openSection(1);
});

</script>


<style scoped>
.content{
    height:100%;
    padding-top:4rem;
    background-color: var(--document-background);
}
</style>
