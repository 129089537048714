<template>
    <div id="cover" ref="cover">
        <div id="banner-container">
            <div id="cover-banner"></div>
        </div>
        <div id="cover-img" ref="coverImg"></div>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";
import textMap from "@/scripts/locale.js";

const content = ref(textMap.cover);
const requestId = ref(null);
const cover = ref(null);
const coverImg = ref(null);
const mousePos = ref({x:0, y:0});
const coverPos = ref({x:0, y:0});

function getMousePos(e){
    mousePos.value.x = e.clientX;
    mousePos.value.y = e.clientY;
}

function animateBackground(){
    coverPos.value.x = parseFloat((coverPos.value.x+(mousePos.value.x - coverPos.value.x)/20).toFixed(2));
    coverPos.value.y = parseFloat((coverPos.value.y+(mousePos.value.y - coverPos.value.y)/20).toFixed(2));
    const width = coverImg.value.offsetWidth;
    const height = coverImg.value.offsetHeight;
    const xPercent = -(coverPos.value.x / width - 0.5)*10;
    const yPercent = -(coverPos.value.y / height - 0.5)*10;
    coverImg.value.style.transform = `translate(${xPercent}%, ${yPercent}%)`;
    requestId.value = requestAnimationFrame(animateBackground);
}

onresize = ()=>{
    if(window.innerWidth>window.innerHeight){
        cover.value.addEventListener('mousemove', getMousePos);
        requestId.value = requestAnimationFrame(animateBackground);
    }else{
        cancelAnimationFrame(requestId.value);
        cover.value.removeEventListener('mousemove', getMousePos);
        coverImg.value.style.transform = `translate(0%, 0%)`;
    }
}

onMounted(()=>{
    if(window.innerWidth>window.innerHeight){
        cover.value.addEventListener('mousemove', getMousePos);
        requestId.value = requestAnimationFrame(animateBackground);
    }    
});

onBeforeUnmount(()=>{
    if(window.innerWidth>window.innerHeight){
        cancelAnimationFrame(requestId.value);
        cover.value.removeEventListener('mousemove', getMousePos);
    }
});
</script>

<style scoped>
#cover{
    position:relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-bottom:-2.5%;
    z-index: 10;
}
#cover-img{
    width: 110%;
    height: 105%;
    background-image: url('~@/assets/cover.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: -5%;
    z-index: -1;
    position: relative;
    top:-90%;
}
#banner-container{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: var(--cover-text);
    height:inherit;
    width: 100%;
}
#cover-banner{
    grid-column:3;
    grid-row: 1;
    background-image: url('~@/assets/large-banner.png');
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 75%;
    width: 75%;
    margin-top:15%;
    margin-right:25%;
}
@media only screen and (max-width: 1279px) {
    #cover{
        height: 50vh;
    }
    #cover-banner{
        display:none;
    }
}
</style>