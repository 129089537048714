<template>
<div>
    <div class="title" @click="toggleSection" >{{title}}</div>
    <div ref="content" :class="{'opened':isOpen,'collapsable':!isSafari}">
        <slot></slot>
    </div>
</div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useSectionStore } from "@/store/sectionStore";

const store = useSectionStore();

const props = defineProps({
    title: String,
    index: Number // index of the section in the store, sadly this is hardcoded for now
});

const isOpen = computed(() => store.openedSection === props.index || isSafari.value);
const isSafari = computed(()=>store.isSafari);

function toggleSection(){
    if(isOpen.value){
        store.closeSection();
    } else {
        store.openSection(props.index);
        store.scrollTo(props.index);
    }
}

</script>

<style scoped>
.title{
    margin-top:1rem;
    font-size:1.5rem; ;
}
.title:hover{
    color:var(--navbar-foreground-highlighted);
    cursor:pointer;
}
.title::after{
    content: "";
    display: block;
    margin-top: 0.1rem;
    width: 60%;
    height: 3px;
    background-color: var(--navbar-foreground-highlighted);
    position: absolute;
    margin-left: 20%;
    border-radius: 25%;
}
.collapsable{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.opened{
    max-height: 2000px;
}
</style>