<template>
<div class="section-container">
    <CollapsedContent :title="content.title" :index="2">
        <div class="container-box">
            <div id="vs-box-left" class="box left">
                <div v-for="text in content.left" :key="text">{{text}}</div>
            </div>
            <div class="list">
                <div v-for="text in content.answers" :key="text">{{text}}</div>
            </div>
            <div id="vs-box-right" class="box right">
                <div>{{ content.right }}</div>
            </div>
        </div>
    </CollapsedContent>
</div>
</template>

<script setup>
import { updateOnScroll,elementHeight,elementBottom } from "basic-scroll-animations";
import CollapsedContent from "@/components/CollapsedContent.vue";
import textMap from "@/scripts/locale.js";
import { ref, onMounted } from "vue";
import { useSectionStore } from "@/store/sectionStore";

const store = useSectionStore();
const content = ref(textMap.visualDesign);

onMounted(()=>{
    if(!store.isSafari){
        let lstart = elementBottom("#boxes");
        let height = 2*elementHeight("#boxes");
        if(window.innerWidth<window.innerHeight){
            lstart = lstart/2;
            height = height/2;
        }
        if(window.innerHeight < 500){
            lstart = 0;
            height = 0.01;
        }
        updateOnScroll("#vs-box-left")
            .from(lstart,{opacity:0, translateX: -100})
            .to(lstart+height,{opacity:1, translateX: 0});
        updateOnScroll("#vs-box-right")
            .from(lstart,{opacity:0, translateX: 100})
            .to(lstart+height,{opacity:1, translateX: 0});
    }
});
</script>

<style scoped>
</style>