<template>
    <Content />
    <Intro />
</template>

<script setup>
import Intro from "@/views/Intro.vue";
import Content from "@/views/Content.vue";
import { onMounted,ref } from "vue";

onMounted(()=>{
    if (history.scrollRestoration) {
        history.scrollRestoration = "manual";
    } else {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }
});


</script>



<style>
html,body{
    margin:0;
    width:100vw;
    height:100vh;
    color: var(--document-foreground);
    background-color: var(--document-background);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x:clip;
}
:root{
    --document-background: #0e0e0e;
    --document-foreground: #ffffff;
    --navbar-background: #0e0e0e;
    --navbar-foreground: #ffffff;
    --navbar-foreground-highlighted: #9d8470;
    --container-background: linear-gradient(135deg, #918b83 0%, #c4bdb8 74%);
    --container-foreground: #000;
    --box-background: #9d8470;
    --box-background-lighter: #aa8f79;
    --box-foreground: #ffffff;

    --primary-accent-light: #f5ede9;
    --secondary-foreground: #e2e2e2;
    --secondary-accent-dark: #96601a;
    --secondary-accent: #c06f2b;
    --secondary-accent-light: #dd9932;
    --cover-text: #fff;
}
.section-container{
    height:100%;
}
.container-box{
    text-align:left;
    margin: 1em 25% 1em;
    padding:2rem;
    background-image: var(--container-background);
    color: var(--container-foreground);
    border-radius: 15px;
    box-shadow: #524e49 0px 0px 10px 0px;
    font-size:1.3rem;
}
.box{
    position: relative;
    background-color: var(--box-background);
    border-radius: 15px;
    color:var(--box-foreground);
    box-shadow: #524e49 0px 0px 10px 0px;
    padding: 1em;
    margin: 1rem 0;
}
.box.left{
    left:-10%;
}
.box.right{
    right:-10%;
    text-align: right;
}
.box>div{
    margin-bottom: 0.25em;
}
.list>div{
    margin-bottom:0.5em;
}
.list>div::before{
    content: "• ";
    margin-left: -0.75rem;
}
@media only screen and (max-width: 1279px) {
    .container-box{
        margin: 1em 5% 1em;
    }
}
</style>
