<template>
<div class="section-container">
    <!--CollapsedContent :title="content.title" :index="6"-->
        <Collage :imageGroup="'reference'" :columns="3"/>
    <!--/CollapsedContent-->
</div>
</template>

<script setup>

import textMap from "@/scripts/locale.js";
import { ref } from "vue";
import Collage from "@/components/Collage.vue";
import CollapsedContent from "@/components/CollapsedContent.vue";



const content = ref(textMap.references);

</script>

<style scoped>
</style>