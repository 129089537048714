<template>
<div class="section-container">
    <CollapsedContent :title="content.title" :index="1">
        <div class="container-box">
            <div class="list">
                <div v-for="text in content.boxLeft" :key="text">{{text}}</div>
                <div>{{content.about}}</div>
            </div>
            <div id="boxes">
                <div id="i-box-right" class="box right">
                    <div class="left-aligned">
                        <div v-for="text in content.boxRight" :key="text">{{text}}</div>
                    </div>
                </div>
            </div>
        </div>
    </CollapsedContent>    
</div>
</template>

<script setup>
import textMap from "@/scripts/locale.js";
import { updateOnScroll,elementHeight,elementBottom, elementTop } from "basic-scroll-animations";
import CollapsedContent from "@/components/CollapsedContent.vue";
import { onMounted, ref } from "vue";
import { useSectionStore } from "@/store/sectionStore";

const store = useSectionStore();
const content = ref(textMap.inspirations);

onMounted(()=>{
    if(!store.isSafari){
        let start = elementBottom("#boxes");
        let height = 2*elementHeight("#boxes");
        if(window.innerWidth<window.innerHeight){
            start = start/2;
            height = height/2;
        }
        if(window.innerHeight < 500){
            start = 0;
            height = 0.01;
        }
        updateOnScroll("#i-box-right")
            .from(start,{opacity:0, translateX: 100})
            .to(start+height,{opacity:1, translateX: 0});
    }
});

</script>

<style scoped>
.left-aligned{
    text-align: left;
    margin-left: auto;
    width: max-content;
}
.left-aligned > div{
    margin-bottom: 0.25em;
}
</style>