<template>
    <div class="footer">
    </div>
</template>



<style scoped>
.footer{
    padding:1em;
}
</style>