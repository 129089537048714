<template>
<div class="section-container">
    <div class="offer-title">{{content.title}}</div>
    <div class="offer-container">
        <div class="contact box">  
            <div class="icons">
                <div @click="copy(content.email)" :title="content.copyTooltip">
                    <img src="@/assets/icons/mail.svg" alt="email"/>
                    <label>{{content.email}}</label>
                </div>
                <div @click="copy(content.phones[0])" :title="content.copyTooltip">
                    <img src="@/assets/icons/phone.svg" alt="phone"/>
                    <label>{{content.phones[0]}}</label>
                </div>
                <div @click="copy(content.phones[1])" :title="content.copyTooltip">
                    <img src="@/assets/icons/phone.svg" alt="phone"/>
                    <label>{{content.phones[1]}}</label>
                </div>
            </div>  
            <div class="form">        
                <div>{{content.nameLabel}}</div>
                <input class="wide" type="text" v-model="name" name="name" />
                <div>{{content.messageLabel}}</div>
                <textarea class="wide" v-model="message" name="message"></textarea>
                <button @click="sendMessage()" name="send">
                    {{content.button}}
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import textMap from "@/scripts/locale.js";
import { ref } from "vue";

const content = ref(textMap.offer);


const name = ref("");
const message = ref("");


function sendMessage(){
    if(name.value.length < 2){
        alert(content.value.noNameAlert);
        return;
    }
    else if(message.value.length < 5){
        alert(content.value.noMessageAlert);
        return;
    }
    else{
        const uri = `mailto:${content.value.email}?subject=${content.value.title}&body=${message.value}\n\n${name.value}`;
        window.open(uri);
    }
}

function copy(value){
    navigator.clipboard.writeText(value);
}
</script>



<style scoped>
.offer-title{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5em;
    margin-top: 2em;
}
.offer-container{
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:stretch;
    padding: 1em;
    margin: 0 auto;
    gap: 1em;
    color: var(--secondary-foreground);
    width:fit-content;
}
.contact{
    display:flex;
    gap:1em;
    padding: 1em 2em;
}
.form{
    display:flex;
    flex-direction:column;
    gap: 0.5em;
    justify-content: flex-start;
    align-items: flex-start;
}
.form>div{
    font-weight: bold;
}
.form>button{
    align-self: flex-end;
}
.wide{
    flex-grow: 1;
    width: 100%;
}
.icons{
    margin-top:1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5em;
}
.icons *{
    display:inline;
    cursor:pointer;
    vertical-align: middle;
}
.icons img{
    margin-right:0.5em;
}
@media only screen and (max-width: 1279px) {
    .contact{
        padding: 0.5em 1em;
    }
}
</style>