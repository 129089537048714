<template>
    <div class="navbar">
        <div class="logo-container">
            <div v-if="!isWidescreen"></div>
            <div class="logo" @click="onItemClicked(0)">
                <div style="background-image: url('banner.svg');" alt="logo" ></div>
            </div>
            <HamburgerButton class="toggle-button" v-if="!isWidescreen" @toggled="onToggled" />
        </div>
        <div class="menu-list"  :class="{'expanded':(buttonValue)}">
            <div v-for="(item,index) of content.items" :key="index" 
                class="menu-item" :class="{'expanded':(buttonValue)}"
                @click="onItemClicked(index+1)">
                <span>
                    {{item}}
                </span>
            </div>
        </div>
    </div>
    <!-- LanguageSelector class="languages" :class="{'right-positioned':isWidescreen,'left-positioned':!isWidescreen}" /-->
</template>

<script setup>
import HamburgerButton from "@/components/HamburgerButton.vue";
import { computed, ref } from "vue";
import textMap from "@/scripts/locale.js";
//import LanguageSelector from "@/components/LanguageSelector.vue";

const emit = defineEmits(['pressed']);

const content = ref(textMap.navbar);
const buttonValue = ref(false);
const isWidescreen = ref(window.innerWidth > 1279);

window.addEventListener('resize', () => {
    isWidescreen.value = window.innerWidth >= 1279;
});

function onToggled(value) {
    buttonValue.value = value;
}
function onItemClicked(index) {
    emit('pressed', index);
}

</script>

<style scoped>
.logo-container{
    display: grid;
    align-content: center;
    flex-grow: 1;
    justify-content: center;
    grid-template-columns: 3fr 1fr 3fr;
    background-color: var(--navbar-background);
}
.logo{
    margin: auto auto auto 0;
    color: var(--secondary-accent);
    min-width: 10em;
    cursor:pointer;
}
.logo>div{
    min-height: 3rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0.2rem;
}
.navbar{
    top: 0;
    display:flex;
    position: fixed;
    justify-content: flex-end;
    align-content: center;
    background-color: var(--navbar-background);
    color: var(--navbar-foreground);
    padding: 0 7.5%;
    width: calc( 100% - 15%);
    z-index: 90;
}
.toggle-button{
    transform: scale(0.5);
    justify-self: end;
    align-self: center;
}
.menu-list{
    display:flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-content: center;

}
.menu-item{
    position: relative;
    padding: 1em 0.5em;
    white-space: nowrap;
    margin-right: 0.5rem;
    cursor: pointer;
    transition: color 0.25s;
    background-color: var(--navbar-background);
}
.menu-item:last-child{
    margin-left: 6em;
}
.menu-item:hover{
    color: var(--navbar-foreground-highlighted);
}
.menu-item>span{
    position: relative;
}
.menu-item>span::after{
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 2px;
    background-color: var(--navbar-foreground-highlighted);
    margin-top: 0.25em;
    transition: width 0.5s;
    left: 0;
}
.menu-item:hover>span::after{
    width: 100%;
}
.right-positioned{
    right: 0;
}
.left-positioned{
    left: 0;
}

@media only screen and (max-width: 1279px) {
    .navbar{
        flex-direction: column;
        align-items: flex-end;
        padding: 0 ;
        width:100%;
        background-color: rgba(0,0,0,0);
    }
    .menu-list{
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;
        max-height: 0;
        z-index:-10;
    }
    .menu-list.expanded{
        max-height: 100vh;
    }
    .menu-item{
        position: relative;
        top: 0;
        padding: 0.5em;
        margin: 0;
        width:10em;
        opacity: 0;
        text-align: end;
        transform: translateY(-50vh);
        transform-origin: top;
        transition: transform 0.5s ease-in-out, opacity 0.5s linear;
    }
    .menu-item.expanded{
        transform: translateY(0);
        opacity: 1;
    }
    .menu-item:last-child{
        padding-bottom:1em;
    }
    .logo{
        margin: auto 0;
    }
    .logo-container{
        width: 100%;
    }
}

</style>